import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
import * as Types from '../../../server/graphql/__generated.types';

import {
  TeamFragmentDoc,
  TeamMemberFragmentDoc,
  UserSubscriptionFragmentDoc,
} from '../../components/fragments.generated';

const defaultOptions = {} as const;
export type UserInvitationFragment = {
  __typename?: 'UserInvitation';
  id: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  teamId?: string | null;
  teamRole?: Types.TeamRole | null;
  invitationCode: string;
  invitationStatus: Types.UserInvitationStatus;
  updatedAt: string;
};

export type UserContextQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserContextQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    permissions: Array<string>;
    flags: Array<string>;
    abilities: string;
    isVerified: boolean;
    isAdmin: boolean;
    hasAcceptedTerms: boolean;
    clipsGenerated: number;
    studioProjectVersions: Array<Types.StudioProjectVersion>;
    profile: {
      __typename?: 'Profile';
      name?: string | null;
      givenName?: string | null;
      familyName?: string | null;
      email: string;
      emailVerified: boolean;
      nickname?: string | null;
      picture?: string | null;
      createdAt?: string | null;
      company?: string | null;
      verificationEmailSent: boolean;
    };
    roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
    subscription?: {
      __typename?: 'UserSubscription';
      id: string;
      status: Types.SubscriptionStatus;
      plan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        interval: Types.SubscriptionInterval;
        selectable: boolean;
        displayName: string;
        managed: boolean;
        isMultiSeat: boolean;
        isTrial: boolean;
        tier: Types.SubscriptionPlanTier;
      };
    } | null;
    team?: {
      __typename?: 'Team';
      viewersRoleOnTeam?: Types.TeamRole | null;
      id: string;
      companyName?: string | null;
      ssoConnectionName?: string | null;
      managed: boolean;
      members: Array<{
        __typename?: 'TeamMember';
        id: string;
        name: string;
        email: string;
        initials: string;
        role: Types.TeamRole;
        avatar?: string | null;
      }>;
      subscription?: {
        __typename?: 'UserSubscription';
        id: string;
        status: Types.SubscriptionStatus;
        plan: {
          __typename?: 'SubscriptionPlan';
          id: string;
          interval: Types.SubscriptionInterval;
          selectable: boolean;
          displayName: string;
          managed: boolean;
          isMultiSeat: boolean;
          isTrial: boolean;
          tier: Types.SubscriptionPlanTier;
        };
      } | null;
    } | null;
    pendingTeamInvitation?: {
      __typename?: 'UserInvitation';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      teamId?: string | null;
      teamRole?: Types.TeamRole | null;
      invitationCode: string;
      invitationStatus: Types.UserInvitationStatus;
      updatedAt: string;
    } | null;
    settings: {
      __typename?: 'UserSettings';
      displayTTSVersionSelector?: boolean | null;
      defaultTTSVersion?: Types.TtsVersionSelectOption | null;
      globalAudioFileFormatSelected?: boolean | null;
      globalAudioFileFormat?: Types.AudioFileFormat | null;
      includeClipScriptFormats?: Array<Types.ScriptFormat> | null;
      doNotShowClipDeletionConfirmationModal?: boolean | null;
      doNotShowClipPreviewDeletionConfirmationModal?: boolean | null;
      doNotShowNavigationConfirmationModal?: boolean | null;
    };
  };
};

export const UserInvitationFragmentDoc = gql`
  fragment UserInvitationFragment on UserInvitation {
    id
    email
    firstName
    lastName
    teamId
    teamRole
    invitationCode
    invitationStatus
    updatedAt
  }
`;
export const UserContextDocument = gql`
  query UserContext {
    user {
      id
      profile {
        name
        givenName
        familyName
        email
        emailVerified
        nickname
        picture
        createdAt
        company
        verificationEmailSent
      }
      roles {
        id
        name
      }
      permissions
      flags
      abilities
      isVerified
      isAdmin
      hasAcceptedTerms
      clipsGenerated
      studioProjectVersions
      subscription {
        id
        plan {
          id
          interval
          selectable
          displayName
          managed
          isMultiSeat
          isTrial
          tier
        }
        status
      }
      team {
        ...TeamFragment
        viewersRoleOnTeam
        members {
          id
          name
          email
          initials
          role
          avatar
        }
        subscription {
          id
          plan {
            id
            interval
            selectable
            displayName
            managed
            isMultiSeat
            isTrial
            tier
          }
          status
        }
      }
      pendingTeamInvitation {
        ...UserInvitationFragment
      }
      settings {
        displayTTSVersionSelector
        defaultTTSVersion
        globalAudioFileFormatSelected
        globalAudioFileFormat
        includeClipScriptFormats
        doNotShowClipDeletionConfirmationModal
        doNotShowClipPreviewDeletionConfirmationModal
        doNotShowNavigationConfirmationModal
      }
    }
  }
  ${TeamFragmentDoc}
  ${UserInvitationFragmentDoc}
`;

/**
 * __useUserContextQuery__
 *
 * To run a query within a React component, call `useUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserContextQuery(
  baseOptions?: Apollo.QueryHookOptions<UserContextQuery, UserContextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserContextQuery, UserContextQueryVariables>(
    UserContextDocument,
    options
  );
}
export function useUserContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserContextQuery, UserContextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserContextQuery, UserContextQueryVariables>(
    UserContextDocument,
    options
  );
}
export type UserContextQueryHookResult = ReturnType<typeof useUserContextQuery>;
export type UserContextLazyQueryHookResult = ReturnType<typeof useUserContextLazyQuery>;
export type UserContextQueryResult = Apollo.QueryResult<
  UserContextQuery,
  UserContextQueryVariables
>;
