import gql from 'graphql-tag';
import * as Types from '../../server/graphql/__generated.types';

export type ActorFragment = {
  __typename?: 'Actor';
  id: number;
  name: string;
  description: string;
  image: string;
  preview: string;
  previewText: string;
  initials: string;
  accessLevel?: Types.ActorAccessLevel | null;
  availableIn?: Types.SubscriptionTier | null;
  isCustomActor?: boolean | null;
  locale?: Types.Locale | null;
  restrictionTags?: Array<Types.SubscriptionTier> | null;
  tags?: Array<{
    __typename?: 'AvatarTaggingAttribute';
    attribute: string;
    category: Types.AvatarTaggingAttributeCategoryEnum;
    internalTag: boolean;
  }> | null;
  variants: Array<{
    __typename?: 'ActorVariant';
    id: number;
    version: string;
    isBeta?: boolean | null;
    isArchived?: boolean | null;
    locale: string;
    preview?: string | null;
    tags?: Array<{
      __typename?: 'AvatarTaggingAttribute';
      attribute: string;
      category: Types.AvatarTaggingAttributeCategoryEnum;
      internalTag: boolean;
    }> | null;
    userSettings?: {
      __typename?: 'UserAvatarSettings';
      favoritedAt?: string | null;
    } | null;
    teamSettings?: {
      __typename?: 'TeamAvatarSettings';
      favoritedAt?: string | null;
    } | null;
    variantType: {
      __typename?: 'ActorVariantType';
      id: Types.ActorVariantTypeId;
      label: string;
      description?: string | null;
    };
    versions: {
      __typename?: 'ActorVariantVersions';
      previous?: {
        __typename?: 'TTSModelRelease';
        model: string;
        label: string;
        description?: string | null;
        overrideSpeakerVariantId?: number | null;
        features: Array<string>;
        studioProjectVersion?: Types.StudioProjectVersion | null;
      } | null;
      latest: {
        __typename?: 'TTSModelRelease';
        model: string;
        label: string;
        description?: string | null;
        overrideSpeakerVariantId?: number | null;
        features: Array<string>;
        studioProjectVersion?: Types.StudioProjectVersion | null;
      };
      next?: {
        __typename?: 'TTSModelRelease';
        model: string;
        label: string;
        description?: string | null;
        overrideSpeakerVariantId?: number | null;
        features: Array<string>;
        studioProjectVersion?: Types.StudioProjectVersion | null;
      } | null;
    };
  }>;
};

export type ClipActorFragment = {
  __typename?: 'ClipActor';
  id: number;
  image: string;
  name: string;
  initials: string;
  isCustomActor?: boolean | null;
  variant: {
    __typename?: 'ActorVariant';
    id: number;
    locale: string;
    version: string;
    isArchived?: boolean | null;
    variantType: {
      __typename?: 'ActorVariantType';
      id: Types.ActorVariantTypeId;
      label: string;
      description?: string | null;
    };
    versions: {
      __typename?: 'ActorVariantVersions';
      previous?: {
        __typename?: 'TTSModelRelease';
        model: string;
        label: string;
        description?: string | null;
        overrideSpeakerVariantId?: number | null;
        features: Array<string>;
      } | null;
      latest: {
        __typename?: 'TTSModelRelease';
        model: string;
        label: string;
        description?: string | null;
        overrideSpeakerVariantId?: number | null;
        features: Array<string>;
      };
      next?: {
        __typename?: 'TTSModelRelease';
        model: string;
        label: string;
        description?: string | null;
        overrideSpeakerVariantId?: number | null;
        features: Array<string>;
      } | null;
    };
  };
};

export type ClipFragment = {
  __typename?: 'Clip';
  id: string;
  projectId: string;
  name: string;
  createdAt: string;
  actorId: string;
  index: number;
  sequence: string;
  fileName: string;
  text: string;
  textFormat?: string | null;
  src: string;
  isDeleted?: boolean | null;
  isCreditableBy?: string | null;
  audioFormat: string;
  combinedFrom?: Array<string> | null;
  isComplete: boolean;
  localSrc?: string | null;
  failedWithError?: string | null;
  streamRequest?: StreamRequest | null;
  ttsVersion?: string | null;
  actor: {
    __typename?: 'ClipActor';
    id: number;
    image: string;
    name: string;
    initials: string;
    isCustomActor?: boolean | null;
    variant: {
      __typename?: 'ActorVariant';
      id: number;
      locale: string;
      version: string;
      isArchived?: boolean | null;
      variantType: {
        __typename?: 'ActorVariantType';
        id: Types.ActorVariantTypeId;
        label: string;
        description?: string | null;
      };
      versions: {
        __typename?: 'ActorVariantVersions';
        previous?: {
          __typename?: 'TTSModelRelease';
          model: string;
          label: string;
          description?: string | null;
          overrideSpeakerVariantId?: number | null;
          features: Array<string>;
        } | null;
        latest: {
          __typename?: 'TTSModelRelease';
          model: string;
          label: string;
          description?: string | null;
          overrideSpeakerVariantId?: number | null;
          features: Array<string>;
        };
        next?: {
          __typename?: 'TTSModelRelease';
          model: string;
          label: string;
          description?: string | null;
          overrideSpeakerVariantId?: number | null;
          features: Array<string>;
        } | null;
      };
    };
  };
};

export type SubscriptionClipFragment = {
  __typename?: 'SubscriptionClip';
  id: string;
  projectId: string;
  createdAt: string;
  name: string;
  fileName: string;
  text: string;
  textFormat?: string | null;
  isDeleted?: boolean | null;
  isComplete: boolean;
  index: number;
  sequence: string;
  src: string;
  actorId: string;
  actorVariantId: string;
  audioFormat: string;
  ttsVersion?: string | null;
  combinedFrom?: Array<string> | null;
  isCreditableBy?: string | null;
  actor: {
    __typename?: 'ClipActor';
    id: number;
    image: string;
    name: string;
    initials: string;
    isCustomActor?: boolean | null;
    variant: {
      __typename?: 'ActorVariant';
      id: number;
      locale: string;
      version: string;
      isArchived?: boolean | null;
      variantType: {
        __typename?: 'ActorVariantType';
        id: Types.ActorVariantTypeId;
        label: string;
        description?: string | null;
      };
      versions: {
        __typename?: 'ActorVariantVersions';
        previous?: {
          __typename?: 'TTSModelRelease';
          model: string;
          label: string;
          description?: string | null;
          overrideSpeakerVariantId?: number | null;
          features: Array<string>;
        } | null;
        latest: {
          __typename?: 'TTSModelRelease';
          model: string;
          label: string;
          description?: string | null;
          overrideSpeakerVariantId?: number | null;
          features: Array<string>;
        };
        next?: {
          __typename?: 'TTSModelRelease';
          model: string;
          label: string;
          description?: string | null;
          overrideSpeakerVariantId?: number | null;
          features: Array<string>;
        } | null;
      };
    };
  };
};

export type ProjectFragment = {
  __typename?: 'Project';
  id: string;
  createdAt: string;
  updatedAt: string;
  folderId?: string | null;
  driveId?: string | null;
  name: string;
  clientName?: string | null;
  clipsCount: number;
  creatorId: string;
  sequence: any;
  shareableUrl?: string | null;
  studioVersion: Types.StudioProjectVersion;
  rootDriveId?: string | null;
  shareable?: {
    __typename?: 'ProjectShareable';
    url?: string | null;
    deletedAt?: string | null;
  } | null;
  projectAccess: {
    __typename?: 'ProjectAccess';
    id: string;
    viewerAccessRole?: {
      __typename?: 'ProjectAccessRole';
      id: Types.ProjectAccessRoleEnum;
      permissions: string;
    } | null;
    teamWideAccessRole?: {
      __typename?: 'ProjectAccessRole';
      id: Types.ProjectAccessRoleEnum;
    } | null;
  };
};

export type FolderFragment = {
  __typename?: 'Folder';
  id: string;
  driveId?: string | null;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
  creatorId: string;
  projectCount: number;
  folderCount: number;
};

export type DriveAccessFragment = {
  __typename?: 'DriveAccessRole';
  role: Types.DriveAccessRoleEnum;
};

export type DriveFragment = {
  __typename?: 'UserDrive';
  id: string;
  teamId?: string | null;
  name: string;
  createdAt: string;
  updatedAt: string;
  settings?: { __typename?: 'DriveSettings'; color?: string | null } | null;
  driveAccess: { __typename?: 'DriveAccessRole'; role: Types.DriveAccessRoleEnum };
};

export type UserFragment = {
  __typename?: 'User';
  id: string;
  profile: {
    __typename?: 'Profile';
    name?: string | null;
    familyName?: string | null;
    givenName?: string | null;
    email: string;
  };
};

export type SubscriptionProjectFragment = {
  __typename?: 'SubscriptionProject';
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  creatorId: string;
  clientName?: string | null;
  studioVersion: Types.StudioProjectVersion;
  shareable?: { __typename?: 'ProjectShareable'; url?: string | null } | null;
};

export type SubscriptionProjectAccessFragment = {
  __typename?: 'SubscriptionProjectAccess';
  id: string;
  projectId: string;
  userId?: string | null;
  teamId?: string | null;
  role?: Types.ProjectAccessRoleEnum | null;
  sequence: any;
};

export type ProjectAccessFragment = {
  __typename?: 'Project';
  id: string;
  projectAccess: {
    __typename?: 'ProjectAccess';
    id: string;
    viewerAccessRole?: {
      __typename?: 'ProjectAccessRole';
      id: Types.ProjectAccessRoleEnum;
      permissions: string;
    } | null;
    teamWideAccessRole?: {
      __typename?: 'ProjectAccessRole';
      id: Types.ProjectAccessRoleEnum;
      label: string;
      permissions: string;
    } | null;
    teamMemberAccess?: Array<{
      __typename?: 'TeamMemberAccess';
      id: string;
      teamMember: {
        __typename?: 'TeamMember';
        id: string;
        name: string;
        avatar?: string | null;
      };
      projectAccessRole: {
        __typename?: 'ProjectAccessRole';
        id: Types.ProjectAccessRoleEnum;
        label: string;
        permissions: string;
      };
    }> | null;
  };
};

export type ProjectAccessSubscriptionFragment = {
  __typename?: 'ProjectAccess';
  id: string;
  viewerAccessRole?: {
    __typename?: 'ProjectAccessRole';
    id: Types.ProjectAccessRoleEnum;
    label: string;
    permissions: string;
  } | null;
  teamWideAccessRole?: {
    __typename?: 'ProjectAccessRole';
    id: Types.ProjectAccessRoleEnum;
    label: string;
    permissions: string;
  } | null;
  teamMemberAccess?: Array<{
    __typename?: 'TeamMemberAccess';
    id: string;
    teamMember: { __typename?: 'TeamMember'; id: string };
    projectAccessRole: {
      __typename?: 'ProjectAccessRole';
      id: Types.ProjectAccessRoleEnum;
      label: string;
      permissions: string;
    };
  }> | null;
};

export type TeamMemberProjectAccessFragment = {
  __typename?: 'ProjectAccess';
  teamMemberAccess?: Array<{
    __typename?: 'TeamMemberAccess';
    id: string;
    teamMember: { __typename?: 'TeamMember'; id: string };
    projectAccessRole: {
      __typename?: 'ProjectAccessRole';
      id: Types.ProjectAccessRoleEnum;
      label: string;
      permissions: string;
    };
  }> | null;
};

export type ViewerProjectAccessFragment = {
  __typename?: 'ProjectAccess';
  viewerAccessRole?: {
    __typename?: 'ProjectAccessRole';
    id: Types.ProjectAccessRoleEnum;
    label: string;
    permissions: string;
  } | null;
};

export type TeamWideProjectAccessFragment = {
  __typename?: 'ProjectAccess';
  teamWideAccessRole?: {
    __typename?: 'ProjectAccessRole';
    id: Types.ProjectAccessRoleEnum;
    label: string;
    permissions: string;
  } | null;
};

export type ProjectAccessRoleFragment = {
  __typename?: 'ProjectAccessRole';
  id: Types.ProjectAccessRoleEnum;
  label: string;
  permissions: string;
};

export type TeamMemberFragment = {
  __typename?: 'TeamMember';
  id: string;
  name: string;
  email: string;
  initials: string;
  role: Types.TeamRole;
  avatar?: string | null;
};

export type SubscriptionPlanDisplayInformationFragment = {
  __typename?: 'SubscriptionPlanDisplayInformation';
  displayName: string;
  displaySubheading: string;
  displayTertiaryHeading: string;
  displayActorCount: number;
  displayActorVariantCount: string;
  displayPrice: string;
  discountLabel?: string | null;
  badgeLabel?: string | null;
  lineItemsHeader?: string | null;
  lineItems: Array<{
    __typename?: 'SubscriptionPlanLineItems';
    text: string;
    emphasisLabel?: string | null;
  }>;
};

export type SubscriptionPlanFragment = {
  __typename?: 'SubscriptionPlan';
  id: string;
  active: boolean;
  amount: number;
  currency: string;
  interval: Types.SubscriptionInterval;
  intervalCount: number;
  productId: string;
  displayName: string;
  selectable: boolean;
  auth0RoleId: string;
  managed: boolean;
  accessLimits: {
    __typename?: 'ProductAccessLimits';
    projectsLimit: number;
    clipGenerationLimit: number;
  };
  discount?: Array<{
    __typename?: 'Discount';
    amount: number;
    coupon?: {
      __typename?: 'Coupon';
      name: string;
      amountOff?: number | null;
      percentOff?: number | null;
    } | null;
  }> | null;
};

export type DiscountFragment = {
  __typename?: 'Discount';
  amount: number;
  coupon?: {
    __typename?: 'Coupon';
    name: string;
    amountOff?: number | null;
    percentOff?: number | null;
  } | null;
};

export type CouponFragment = {
  __typename?: 'Coupon';
  name: string;
  amountOff?: number | null;
  percentOff?: number | null;
};

export type SubscriptionPlanWithDisplayInformationFragment = {
  __typename?: 'SubscriptionPlan';
  id: string;
  active: boolean;
  amount: number;
  currency: string;
  interval: Types.SubscriptionInterval;
  intervalCount: number;
  productId: string;
  displayName: string;
  selectable: boolean;
  auth0RoleId: string;
  managed: boolean;
  displayInformation: {
    __typename?: 'SubscriptionPlanDisplayInformation';
    displayName: string;
    displaySubheading: string;
    displayTertiaryHeading: string;
    displayActorCount: number;
    displayActorVariantCount: string;
    displayPrice: string;
    discountLabel?: string | null;
    badgeLabel?: string | null;
    lineItemsHeader?: string | null;
    lineItems: Array<{
      __typename?: 'SubscriptionPlanLineItems';
      text: string;
      emphasisLabel?: string | null;
    }>;
  };
  accessLimits: {
    __typename?: 'ProductAccessLimits';
    projectsLimit: number;
    clipGenerationLimit: number;
  };
  discount?: Array<{
    __typename?: 'Discount';
    amount: number;
    coupon?: {
      __typename?: 'Coupon';
      name: string;
      amountOff?: number | null;
      percentOff?: number | null;
    } | null;
  }> | null;
};

export type UserSubscriptionFragment = {
  __typename?: 'UserSubscription';
  id: string;
  isTeamSubscription: boolean;
  customerId: string;
  created: number;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  cancelAtPeriodEnd: boolean;
  cancelAt?: number | null;
  canceledAt?: number | null;
  status: Types.SubscriptionStatus;
  churnkeyAuthHash?: string | null;
  billing?: {
    __typename?: 'SubscriptionBilling';
    source?: {
      __typename?: 'BillingSource';
      id: string;
      name: string;
      brand: string;
      expiry: string;
      last4: string;
    } | null;
    addressInfo?: {
      __typename?: 'AddressInfo';
      shipping?: {
        __typename?: 'Shipping';
        name?: string | null;
        address?: {
          __typename?: 'Address';
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          country?: string | null;
        } | null;
      } | null;
      taxId?: { __typename?: 'TaxId'; type: string; value: string } | null;
    } | null;
  } | null;
  discount?: {
    __typename?: 'SubscriptionDiscount';
    id?: string | null;
    coupon?: {
      __typename?: 'SubscriptionCoupon';
      id: string;
      amount_off?: number | null;
      percent_off?: number | null;
      valid: boolean;
    } | null;
  } | null;
  plan: {
    __typename?: 'SubscriptionPlan';
    id: string;
    active: boolean;
    amount: number;
    currency: string;
    interval: Types.SubscriptionInterval;
    intervalCount: number;
    productId: string;
    displayName: string;
    selectable: boolean;
    auth0RoleId: string;
    managed: boolean;
    accessLimits: {
      __typename?: 'ProductAccessLimits';
      projectsLimit: number;
      clipGenerationLimit: number;
    };
    discount?: Array<{
      __typename?: 'Discount';
      amount: number;
      coupon?: {
        __typename?: 'Coupon';
        name: string;
        amountOff?: number | null;
        percentOff?: number | null;
      } | null;
    }> | null;
  };
};

export type BillingSourceFragment = {
  __typename?: 'BillingSource';
  id: string;
  name: string;
  brand: string;
  expiry: string;
  last4: string;
};

export type AddressInfoFragment = {
  __typename?: 'AddressInfo';
  shipping?: {
    __typename?: 'Shipping';
    name?: string | null;
    address?: {
      __typename?: 'Address';
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      country?: string | null;
    } | null;
  } | null;
  taxId?: { __typename?: 'TaxId'; type: string; value: string } | null;
};

export type ClipsListFragment = {
  __typename?: 'ClipsConnection';
  edges: Array<{
    __typename?: 'ClipsEdge';
    cursor?: string | null;
    node: {
      __typename?: 'Clip';
      id: string;
      projectId: string;
      name: string;
      createdAt: string;
      actorId: string;
      index: number;
      sequence: string;
      fileName: string;
      text: string;
      textFormat?: string | null;
      src: string;
      isDeleted?: boolean | null;
      isCreditableBy?: string | null;
      audioFormat: string;
      combinedFrom?: Array<string> | null;
      isComplete: boolean;
      localSrc?: string | null;
      failedWithError?: string | null;
      streamRequest?: StreamRequest | null;
      ttsVersion?: string | null;
      actor: {
        __typename?: 'ClipActor';
        id: number;
        image: string;
        name: string;
        initials: string;
        isCustomActor?: boolean | null;
        variant: {
          __typename?: 'ActorVariant';
          id: number;
          locale: string;
          version: string;
          isArchived?: boolean | null;
          variantType: {
            __typename?: 'ActorVariantType';
            id: Types.ActorVariantTypeId;
            label: string;
            description?: string | null;
          };
          versions: {
            __typename?: 'ActorVariantVersions';
            previous?: {
              __typename?: 'TTSModelRelease';
              model: string;
              label: string;
              description?: string | null;
              overrideSpeakerVariantId?: number | null;
              features: Array<string>;
            } | null;
            latest: {
              __typename?: 'TTSModelRelease';
              model: string;
              label: string;
              description?: string | null;
              overrideSpeakerVariantId?: number | null;
              features: Array<string>;
            };
            next?: {
              __typename?: 'TTSModelRelease';
              model: string;
              label: string;
              description?: string | null;
              overrideSpeakerVariantId?: number | null;
              features: Array<string>;
            } | null;
          };
        };
      };
    };
    highlights?: Array<{
      __typename?: 'ClipsSearchResultHighlights';
      fieldName: string;
      highlight: string;
    }> | null;
  }>;
  pageInfo?: {
    __typename?: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
  } | null;
};

export type ProjectsListFragment = {
  __typename?: 'ProjectsConnection';
  edges: Array<{
    __typename?: 'ProjectEdge';
    cursor: string;
    node: {
      __typename?: 'Project';
      id: string;
      createdAt: string;
      updatedAt: string;
      folderId?: string | null;
      driveId?: string | null;
      name: string;
      clientName?: string | null;
      clipsCount: number;
      creatorId: string;
      sequence: any;
      shareableUrl?: string | null;
      studioVersion: Types.StudioProjectVersion;
      rootDriveId?: string | null;
      shareable?: {
        __typename?: 'ProjectShareable';
        url?: string | null;
        deletedAt?: string | null;
      } | null;
      projectAccess: {
        __typename?: 'ProjectAccess';
        id: string;
        viewerAccessRole?: {
          __typename?: 'ProjectAccessRole';
          id: Types.ProjectAccessRoleEnum;
          permissions: string;
        } | null;
        teamWideAccessRole?: {
          __typename?: 'ProjectAccessRole';
          id: Types.ProjectAccessRoleEnum;
        } | null;
      };
    };
  }>;
  pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean };
};

export type TeamFragment = {
  __typename?: 'Team';
  id: string;
  companyName?: string | null;
  ssoConnectionName?: string | null;
  managed: boolean;
};

export const ActorFragmentDoc = gql`
  fragment ActorFragment on Actor {
    id
    name
    description
    image
    preview
    previewText
    initials
    accessLevel
    availableIn
    isCustomActor
    tags {
      attribute
      category
      internalTag
    }
    locale
    restrictionTags
    variants {
      id
      version
      isBeta
      isArchived
      locale
      preview
      tags {
        attribute
        category
        internalTag
      }
      userSettings {
        favoritedAt
      }
      teamSettings {
        favoritedAt
      }
      variantType {
        id
        label
        description
      }
      versions {
        previous {
          model
          label
          description
          overrideSpeakerVariantId
          features
          studioProjectVersion
        }
        latest {
          model
          label
          description
          overrideSpeakerVariantId
          features
          studioProjectVersion
        }
        next {
          model
          label
          description
          overrideSpeakerVariantId
          features
          studioProjectVersion
        }
      }
    }
  }
`;
export const ClipActorFragmentDoc = gql`
  fragment ClipActorFragment on ClipActor {
    id
    image
    name
    initials
    isCustomActor
    variant {
      id
      locale
      version
      isArchived
      variantType {
        id
        label
        description
      }
      versions {
        previous {
          model
          label
          description
          overrideSpeakerVariantId
          features
        }
        latest {
          model
          label
          description
          overrideSpeakerVariantId
          features
        }
        next {
          model
          label
          description
          overrideSpeakerVariantId
          features
        }
      }
    }
  }
`;
export const SubscriptionClipFragmentDoc = gql`
  fragment SubscriptionClipFragment on SubscriptionClip {
    id
    projectId
    createdAt
    name
    fileName
    text
    textFormat
    isDeleted
    isComplete
    index
    sequence
    src
    actorId
    actorVariantId
    actor {
      ...ClipActorFragment
    }
    audioFormat
    ttsVersion
    combinedFrom
    isCreditableBy
  }
  ${ClipActorFragmentDoc}
`;
export const FolderFragmentDoc = gql`
  fragment FolderFragment on Folder {
    id
    driveId
    name
    description
    createdAt
    updatedAt
    creatorId
    projectCount
    folderCount
  }
`;
export const DriveAccessFragmentDoc = gql`
  fragment DriveAccessFragment on DriveAccessRole {
    role
  }
`;
export const DriveFragmentDoc = gql`
  fragment DriveFragment on UserDrive {
    id
    teamId
    name
    settings {
      color
    }
    createdAt
    updatedAt
    driveAccess {
      role
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment UserFragment on User {
    id
    profile {
      name
      familyName
      givenName
      email
    }
  }
`;
export const SubscriptionProjectFragmentDoc = gql`
  fragment SubscriptionProjectFragment on SubscriptionProject {
    id
    createdAt
    updatedAt
    name
    creatorId
    clientName
    shareable {
      url
    }
    studioVersion
  }
`;
export const SubscriptionProjectAccessFragmentDoc = gql`
  fragment SubscriptionProjectAccessFragment on SubscriptionProjectAccess {
    id
    projectId
    userId
    teamId
    role
    sequence
  }
`;
export const ProjectAccessFragmentDoc = gql`
  fragment ProjectAccessFragment on Project {
    id
    projectAccess {
      id
      viewerAccessRole {
        id
        permissions
      }
      teamWideAccessRole {
        id
        label
        permissions
      }
      teamMemberAccess {
        id
        teamMember {
          id
          name
          avatar
        }
        projectAccessRole {
          id
          label
          permissions
        }
      }
    }
  }
`;
export const ProjectAccessSubscriptionFragmentDoc = gql`
  fragment ProjectAccessSubscriptionFragment on ProjectAccess {
    id
    viewerAccessRole {
      id
      label
      permissions
    }
    teamWideAccessRole {
      id
      label
      permissions
    }
    teamMemberAccess {
      id
      teamMember {
        id
      }
      projectAccessRole {
        id
        label
        permissions
      }
    }
  }
`;
export const TeamMemberProjectAccessFragmentDoc = gql`
  fragment TeamMemberProjectAccessFragment on ProjectAccess {
    teamMemberAccess {
      id
      teamMember {
        id
      }
      projectAccessRole {
        id
        label
        permissions
      }
    }
  }
`;
export const ViewerProjectAccessFragmentDoc = gql`
  fragment ViewerProjectAccessFragment on ProjectAccess {
    viewerAccessRole {
      id
      label
      permissions
    }
  }
`;
export const TeamWideProjectAccessFragmentDoc = gql`
  fragment TeamWideProjectAccessFragment on ProjectAccess {
    teamWideAccessRole {
      id
      label
      permissions
    }
  }
`;
export const ProjectAccessRoleFragmentDoc = gql`
  fragment ProjectAccessRoleFragment on ProjectAccessRole {
    id
    label
    permissions
  }
`;
export const TeamMemberFragmentDoc = gql`
  fragment TeamMemberFragment on TeamMember {
    id
    name
    email
    initials
    role
    avatar
  }
`;
export const CouponFragmentDoc = gql`
  fragment CouponFragment on Coupon {
    name
    amountOff
    percentOff
  }
`;
export const DiscountFragmentDoc = gql`
  fragment DiscountFragment on Discount {
    amount
    coupon {
      ...CouponFragment
    }
  }
  ${CouponFragmentDoc}
`;
export const SubscriptionPlanFragmentDoc = gql`
  fragment SubscriptionPlanFragment on SubscriptionPlan {
    id
    active
    amount
    currency
    interval
    intervalCount
    productId
    displayName
    selectable
    accessLimits {
      projectsLimit
      clipGenerationLimit
    }
    auth0RoleId
    discount {
      ...DiscountFragment
    }
    managed
  }
  ${DiscountFragmentDoc}
`;
export const SubscriptionPlanDisplayInformationFragmentDoc = gql`
  fragment SubscriptionPlanDisplayInformationFragment on SubscriptionPlanDisplayInformation {
    displayName
    displaySubheading
    displayTertiaryHeading
    displayActorCount
    displayActorVariantCount
    displayPrice
    discountLabel
    badgeLabel
    lineItemsHeader
    lineItems {
      text
      emphasisLabel
    }
  }
`;
export const SubscriptionPlanWithDisplayInformationFragmentDoc = gql`
  fragment SubscriptionPlanWithDisplayInformationFragment on SubscriptionPlan {
    ...SubscriptionPlanFragment
    displayInformation {
      ...SubscriptionPlanDisplayInformationFragment
    }
  }
  ${SubscriptionPlanFragmentDoc}
  ${SubscriptionPlanDisplayInformationFragmentDoc}
`;
export const BillingSourceFragmentDoc = gql`
  fragment BillingSourceFragment on BillingSource {
    id
    name
    brand
    expiry
    last4
  }
`;
export const AddressInfoFragmentDoc = gql`
  fragment AddressInfoFragment on AddressInfo {
    shipping {
      address {
        line1
        line2
        city
        state
        postalCode
        country
      }
      name
    }
    taxId {
      type
      value
    }
  }
`;
export const UserSubscriptionFragmentDoc = gql`
  fragment UserSubscriptionFragment on UserSubscription {
    id
    isTeamSubscription
    customerId
    created
    currentPeriodStart
    currentPeriodEnd
    cancelAtPeriodEnd
    cancelAt
    canceledAt
    status
    billing {
      source {
        ...BillingSourceFragment
      }
      addressInfo {
        ...AddressInfoFragment
      }
    }
    discount {
      id
      coupon {
        id
        amount_off
        percent_off
        valid
      }
    }
    plan {
      ...SubscriptionPlanFragment
    }
    churnkeyAuthHash
  }
  ${BillingSourceFragmentDoc}
  ${AddressInfoFragmentDoc}
  ${SubscriptionPlanFragmentDoc}
`;
export const ClipFragmentDoc = gql`
  fragment ClipFragment on Clip {
    id
    projectId
    name
    createdAt
    actorId
    actor {
      ...ClipActorFragment
    }
    index
    sequence
    fileName
    text
    textFormat
    src
    isDeleted
    isCreditableBy
    audioFormat
    combinedFrom
    isComplete
    localSrc @client
    failedWithError @client
    streamRequest @client
    ttsVersion
  }
  ${ClipActorFragmentDoc}
`;
export const ClipsListFragmentDoc = gql`
  fragment ClipsListFragment on ClipsConnection {
    edges {
      node {
        ...ClipFragment
      }
      cursor
      highlights {
        fieldName
        highlight
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${ClipFragmentDoc}
`;
export const ProjectFragmentDoc = gql`
  fragment ProjectFragment on Project {
    id
    createdAt
    updatedAt
    folderId
    driveId
    name
    clientName
    clipsCount
    creatorId
    sequence
    shareable {
      url
      deletedAt
    }
    shareableUrl
    studioVersion
    projectAccess {
      id
      viewerAccessRole {
        id
        permissions
      }
      teamWideAccessRole {
        id
      }
    }
    rootDriveId
  }
`;
export const ProjectsListFragmentDoc = gql`
  fragment ProjectsListFragment on ProjectsConnection {
    edges {
      node {
        ...ProjectFragment
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${ProjectFragmentDoc}
`;
export const TeamFragmentDoc = gql`
  fragment TeamFragment on Team {
    id
    companyName
    ssoConnectionName
    managed
  }
`;
